<template>
    <div>
        <el-form :inline="true" :model="form">
            <el-form-item>
                <el-input v-model="form.id" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.userId" placeholder="用户ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.email" placeholder="用户邮箱"></el-input>
            </el-form-item>

            <el-form-item>
                <el-select v-model="form.status" placeholder="状态" clearable>
                    <el-option v-for="item in Object.keys(TRANS_ORDER_STATUS)" :key="item"
                        :label="TRANS_ORDER_STATUS[item]" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="queryTimeRange" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="page.list" border>
            <el-table-column label="ID">
                <template #default="{ row }">
                    {{ row.id }}
                    <el-tag :type="choseTag(row.status)">{{ TRANS_ORDER_STATUS[row.status] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发起时间">
            </el-table-column>
            <el-table-column label="用户">
                <template #default="{ row }">
                    [{{ row.userId }}]{{ row.email }}
                </template>
            </el-table-column>
            <el-table-column label="收款账户">
                <template #default="{ row }">
                    {{ row.raInfo.bank }}  <el-divider direction="vertical"></el-divider> {{ row.raInfo.account }}  <el-divider direction="vertical"></el-divider> {{ row.raInfo.name }}
                </template>
            </el-table-column>
            <el-table-column label="转账账户">
                <template #default="{ row }">
                    {{ row.bank }}  <el-divider direction="vertical"></el-divider> {{ row.account }}
                </template>
            </el-table-column>
            <el-table-column  label="金额/流水号/时间" >
                <template #default="{row}">
                    {{ row.amount }}<el-divider direction="vertical"></el-divider> {{ row.tradeNo }}<el-divider direction="vertical"></el-divider>{{ row.tradeTime }}
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column label="处理人">
                <template #default="{row}" >
                    <template v-if="row.status != 'PROCESSING'">
                        [{{ row.handler }}]{{ row.handlerName }}<el-divider direction="vertical"></el-divider> {{ row.handlerTime }}
                    </template>
                    
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="{ row }">
                    <template v-if="row.status == 'PROCESSING'">
                        <el-popconfirm title="是否确定订单？" @confirm="doMarkSuccess(row.id)">
                            <el-button slot="reference" type="success" size="medium">确认到账</el-button>
                        </el-popconfirm>
                        <el-divider direction="vertical"></el-divider>
                        <el-popover placement="left">
                            <el-form ref="form" :model="row" label-width="80px">
                                <el-form-item label="理由">
                                    <el-input v-model="row.remark"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="doMarkFail(row)">确定</el-button>
                                </el-form-item>
                            </el-form>
                            <el-button slot="reference" type="danger" size="medium">失败</el-button>
                        </el-popover>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import msg from '../../util/msg'
import { list, markSuccess, markFail } from './to'
import { TRANS_ORDER_STATUS } from '../../util/constants'
export default {
    name: "UserList",
    data() {
        return {
            TRANS_ORDER_STATUS,

            queryTimeRange: null,
            form: {
                pageNo: 1,
                pageSize: 20,
                id: null,
                userId: null,
                email: null,
                status: null,
                ctStart: null,
                ctEnd: null,
            },

            page: {
                list: null,
                totalPage: null,
            },
        };
    },

    methods: {
        loadList() {
            if (this.queryTimeRange != null) {
                this.form.ctStart = this.queryTimeRange[0];
                this.form.ctEnd = this.queryTimeRange[1];
            } else {
                this.form.ctStart = null;
                this.form.ctEnd = null;
            }
            list(this.form).then((data) => {
                this.page = data;
            });
        },
        choseTag(status) {
            if (status == 'SUCCESS') {
                return 'success'
            }
            if (status == 'FAIL') {
                return 'danger'
            }

            return '';
        },

        doMarkSuccess(id) {
            markSuccess(id).then(data => {
                msg.success();
                this.loadList();
            })
        },

        doMarkFail(param) {
            if (param.remark == null || param.remark == '') {
                msg.fail('请输入原因');
                return;
            }

            markFail(param).then(data => {
                msg.success();
                this.loadList();
            })
        }
    },

    created() {
        this.loadList();
    },

};
</script>