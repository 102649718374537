import axios from "axios";
import msg from "../../util/msg.js";

export function list(param) {
  return axios.get("/to/list", { params: param });
}

export function markSuccess(id) {
  return axios.post("/to/markSuccess/" + id);
}

export function markFail(param) {
  return axios.post("/to/markFail", param)
}
